#mobile-menu {
	@apply opacity-0 hidden;
}

body.mm-ocd-opened {
	@apply w-full;
	position: fixed !important;
}

.mm ul.mm--parent {
	left: -100%;
	overflow-y: hidden;
}

.mm-ocd {
	top: 88px; 

	#mobile-menu {
		@apply opacity-100 block;
	}

	&__content {
		@apply bg-violet w-full max-w-none;
	}

	&__backdrop {
		@apply hidden;
	}

	.mm-spn.mm-spn--navbar ul {
		top: 88px;
	}

	.mm-spn.mm-spn--navbar ul::before {
		@apply border-none;
	}

	.mm-spn.mm-spn--light {
		@apply bg-violet;
	}

	.mm-spn li.parent-li:before {
			@apply opacity-100;
		}

		.mm-spn li {
			> a {
				@apply w-full
			}
			&.parent-li {
				> a {
					width: calc(100% - 48px);
				}
				ul {
					@apply opacity-0;
	
					&.mm-spn--open {
						@apply opacity-100;
					}
				}
			}
		}

	.mm-spn a {
		@apply px-2.5 text-white py-1 rounded-full text-2xl font-TWKReg border-none;

		&.active {
			@apply bg-white text-violet inline;
		}
	}

	.mm-spn li::after {
		@apply ml-0 opacity-100 hidden;
	}

	.mm-spn a:not(:last-child)::after {
		@apply border-none;
	}

	.mm-spn li {
		@apply px-6 border-none my-6;

		&:before { 
			@apply hidden border-none;
		}

		&.active {
			> a {
				@apply relative;

				&:before {
					content: "";
					width: 10px;
					height: calc(100% - 48px);
					@apply absolute left-0 bottom-0 mb-6;
				}
			}
		}
		&.parent-li {
			&:before {
				background: url(/img/svg/angle-right-solid.svg) no-repeat;
				background-size: 14px;
				transform: rotate(0deg);
				@apply opacity-100 block top-0 right-0 mr-6 h-full bg-center w-12;
			}

			ul li a {
				@apply font-normal;
			}
		}
	}

	.mm-spn.mm-spn--navbar::before {
		background: url(/img/svg/angle-right-solid.svg) no-repeat;
		background-size: 14px;
		height: 25px;
		transform: rotate(-180deg);
		top: 54px;
		@apply z-20 opacity-100 border-none bg-center w-5;
	}

	.mm-spn.mm-spn--navbar::after {
		@apply opacity-100 text-white text-left pl-14 font-TWKReg pt-6 text-2xl;
		height: 70px;
		top: 28px;
		content: "Back" !important;
	}

	.mm-spn.mm-spn--navbar.mm-spn--main::after {
		@apply pl-6 font-TWKReg;
		content: "" !important;
	}

}

