$hamburger-padding-x: 0;
$hamburger-padding-y: 0px;
$hamburger-types: (squeeze);
$hamburger-layer-color: #000000;
$hamburger-layer-border-radius: 2px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: #000000;
$hamburger-layer-width: 32px;
$hamburger-layer-spacing: 8px;
$hamburger-layer-height: 3px;

.header {
	.hamburger {
		@apply mt-2;
	}
}

.home {
	.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
		background-color: #ffffff !important;

	} 

}